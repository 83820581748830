/** @jsx jsx */
import { graphql } from "gatsby"
import { Fragment, useState } from "react"

import { Container, Dialog, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { useTranslation } from "@trueskin-web/translations"

import medicineIcon from "../images/ingredient-medicine-icon.svg"
import moisturizerIcon from "../images/ingredient-moisturizer-icon.svg"

const FilterButton = ({ isActive, ...otherProps }) => (
  <button
    sx={{
      display: "inline-flex",
      appearance: "none",
      alignItems: "center",
      justifyContent: "center",
      mx: 0,
      mb: "-2px",
      px: 4,
      py: 3,
      userSelect: "none",
      verticalAlign: "middle",
      lineHeight: "1.2",
      fontFamily: "inherit",
      fontSize: 4,
      bg: "transparent",
      border: 0,
      borderBottom: "2px solid",
      borderBottomColor: isActive ? "primary" : "transparent",
      transition: "all .1s ease-in",
      cursor: "pointer",
      "&:hover": {
        color: "primaryLight",
      },
      "&:active, &:focus": {
        color: "primaryDark",
      },
    }}
    type="button"
    {...otherProps}
  />
)

const IngredientIcon = ({ ingredient }) => (
  <img
    src={ingredient.type === "moisturizer" ? moisturizerIcon : medicineIcon}
    alt=""
  />
)

const IngredientDetails = ({ ingredient, onDismiss }) => (
  <Dialog
    aria-label="Ingredient Details"
    title={
      <Fragment>
        <IngredientIcon ingredient={ingredient} />
        <div sx={{ mt: 4 }}>{ingredient.title}</div>
      </Fragment>
    }
    onDismiss={onDismiss}
  >
    <p sx={{ my: 0 }}>{ingredient.description}</p>
  </Dialog>
)

const Ingredients = ({ title, ingredients }) => {
  const { t } = useTranslation()
  const [filterByType, setFilterByType] = useState(null)
  const [showIngredientDetails, setShowIngredientDetails] = useState(null)

  return (
    <Fragment>
      <div sx={{ variant: "layout.sectionPadding", bg: "lightYellow" }}>
        <Container>
          <Heading size="lg" mb={[3, 4]}>
            {title}
          </Heading>

          <div
            sx={{
              display: "flex",
              mb: [5, 7],
              borderBottom: "2px solid",
              borderColor: "grey",
            }}
          >
            <FilterButton
              isActive={filterByType === null}
              onClick={() => setFilterByType(null)}
            >
              {t("Ingredients.all")}
            </FilterButton>
            <FilterButton
              isActive={filterByType === "medicine"}
              onClick={() => setFilterByType("medicine")}
            >
              {t("Ingredients.medicine")}
            </FilterButton>
            <FilterButton
              isActive={filterByType === "moisturizer"}
              onClick={() => setFilterByType("moisturizer")}
            >
              {t("Ingredients.moisturizer")}
            </FilterButton>
          </div>

          <ul
            sx={{
              display: "grid",
              gridTemplateColumns: ["repeat(2, 1fr)", "repeat(5, 1fr)"],
              gap: 4,
              listStyle: "none",
              my: 0,
              pl: 0,
            }}
          >
            {ingredients
              .filter((ingredient) =>
                filterByType ? ingredient.type === filterByType : true
              )
              .sort((a, b) => {
                if (a.title < b.title) {
                  return -1
                }
                if (a.title > b.title) {
                  return 1
                }
                return 0
              })
              .map((ingredient) => (
                <li
                  key={ingredient.title}
                  sx={{
                    position: "relative",
                    "&:before": {
                      content: `""`,
                      display: "block",
                      height: 0,
                      pb: "100%",
                    },
                  }}
                >
                  <button
                    type="button"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      width: "100%",
                      mx: 0,
                      p: 4,
                      bg: "transparent",
                      border: "1px",
                      textAlign: "left",
                      appearance: "none",
                      cursor: "pointer",
                      "&:hover": {
                        color: "primaryLight",
                      },
                      "&:active, &:focus": {
                        color: "primaryDark",
                      },
                    }}
                    onClick={() => setShowIngredientDetails(ingredient)}
                  >
                    <IngredientIcon ingredient={ingredient} />
                    <div sx={{ fontSize: 4 }}>{ingredient.title}</div>
                  </button>
                </li>
              ))}
          </ul>
        </Container>
      </div>

      {showIngredientDetails && (
        <IngredientDetails
          ingredient={showIngredientDetails}
          onDismiss={() => setShowIngredientDetails(null)}
        />
      )}
    </Fragment>
  )
}

export default Ingredients

export const ingredientsFragment = graphql`
  fragment IngredientsFragment on Strapi_ComponentBlocksIngredients {
    title
    ingredients {
      title
      type
      description
    }
  }
`
